<template>
<div>
	<v-btn depressed color="primary" @click="toPage">去装修</v-btn>
</div>
</template>

<script>
export default {
	methods: {
		toPage() {
			window.open('/pc-decoration-details', '_blank')
		}
	}
}
</script>

<style scoped>

</style>
